import "../css/kampanja.css";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  StyledEngineProvider,
  Button,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { Outlet, Link, useParams } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";

import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import PreviewModal from "./PreviewModal";
const BannerCard = (props) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        className="banner-box-wrap"
        sx={{
          boxShadow: 5,
          borderRadius: 2,
          p: 1.2,
          mt: 1,
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <Container className="banner-box">
          <div className="banner-texts">
            <Typography className="banner-size-text" variant="h6">
              {props.size}
            </Typography>

            <Typography className="banner-description-text" variant="body2">
              {props.description}
            </Typography>
          </div>
          <img
            className="thumbnail-image"
            src={`https://banner-platform-api.sst.fi/${props.thumbnail}`}
            alt="thumbnail"
          ></img>
        </Container>
        <div className="banner-card-buttons">
          <div className="button-border-right">
            <IconButton
              color="secondary"
              onClick={() => props.onDelete(props.bannerId)}
              className="delete-icon"
            >
              {" "}
              <DeleteIcon />
              Poista
            </IconButton>
          </div>
          <div className="divider" />
          <div className="button-border-left">
            <PreviewModal />
            <IconButton
              color="secondary"
              className="preview-button"
              onClick={() => props.preview(props.bannerId)}
            >
              <PreviewIcon></PreviewIcon>
              Esikatsele tai lataa
            </IconButton>
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default BannerCard;
