import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { Typography, AppBar, CardMedia, Container, Grid, Box, Color, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, Button, CssBaseline, Avatar, TextField } from "@mui/material";

import './App.css';
import './data.js';
import Header from "./components/Header";
import KampanjaUI from "./routes/KampanjaUI";
import React from "react";

import { ClassNames } from "@emotion/react";




const App = () => {

  return (
    <>
      <Header />
    
      <Outlet />
    </>
  );
}



export default App;

/**
 * <Box
  sx={{
    // some styles
    '& .ChildSelector': {
      bgcolor: 'primary.main',
    },
  }}
>

 */