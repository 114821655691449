import React, { useState, useEffect } from "react";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import {
  Typography,
  AppBar,
  CardMedia,
  Container,
  Grid,
  Box,
} from "@mui/material";

import { StyledEngineProvider } from "@mui/material/styles";
import axios from "axios";
import "../css/preview.css";
import CustomerHeader from "../components/CustomerHeader";
import { useParams } from "react-router-dom";

const Preview = (props) => {
  const [campaign, setCampaign] = React.useState({});
  const [banners, setBanners] = React.useState([]);

  let params = useParams();
  useEffect(() => {
    axios
      .get("https://banner-platform-api.sst.fi/api/campaigns/" + params.id)
      .then((res) => {
        setCampaign(res.data);
        console.log("GET Successful");
        setBanners(res.data.banners);
        console.log(banners);
        getAndUpdateBanners();
      });
  }, []);

  const getAndUpdateBanners = () => {
    axios
      .get(
        `https://banner-platform-api.sst.fi/api/banners/?filter=campaignId||$eq||${params.id}`
      )
      .then((res) => {
        setBanners(res.data);
      });
  };

  return (
    <>
      {" "}
      <CustomerHeader customer={campaign.name} desc={campaign.description} />
      <StyledEngineProvider injectFirst>
        <main>
          <div>
            <Grid container spacing={2}>
              {banners.map((banner) => (
                <BannerFrame
                  key={banner.id}
                  location={banner.location}
                  width={banner.width}
                  height={banner.height}
                  title={`${banner.width}  x  ${banner.height}`}
                  header={`${banner.width}  x  ${banner.height}`}
                  desc={banner.description}
                />
              ))}
            </Grid>

            <Outlet />
          </div>
        </main>
      </StyledEngineProvider>
    </>
  );
};

const BannerFrame = (props) => {
  return (
    <Grid item sx={{ m: 1 }}>
      <Box
        sx={{
          boxShadow: 10,
          borderRadius: 2,
          backgroundColor: "whitesmoke",
          p: 2,
          pb: 5,
          m: 1,
        }}
      >
        <iframe
          src={`https://banner-platform-api.sst.fi/${props.location}`}
          scrolling="no"
          width={props.width}
          height={props.height}
          title={props.title}
        ></iframe>
        <Typography variant="h6" sx={{ fontWeight: "bold", ml: 2 }}>
          {" "}
          {props.header}
        </Typography>
        <Typography variant="h7" sx={{ fontWeight: "bold", ml: 2 }}>
          {" "}
          {props.desc}
        </Typography>
      </Box>
    </Grid>
  );
};
export default Preview;

/**
 * Kampanjasivu: http://localhost:3000/kampanjat/01G38WHGF4CWRYWKHBH0HAB9PG
 * Asiakassivu: http://localhost:3000/asiakas/kampanjat/01G38WHGF4CWRYWKHBH0HAB9PG
 */
