import "../css/kampanja.css";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  StyledEngineProvider,
  Button,
  IconButton,
  ThemeProvider,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio
  
 
} from "@mui/material";
import { Outlet, Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";


const CreateBanner = (props) => {
  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        mt:3,
        bgcolor: "white",
      }}
    >
      <Typography className="campaign-name" variant="h5">
        {props.componentName}
      </Typography>
      <FormControl sx={{p:1}} component="fieldset" variant="standard">
          <FormLabel component="legend">Valitse media</FormLabel>
          <RadioGroup
          name="banner-radio-buttons"
          defaultValue="gdn"
          value={props.value}
          onChange={props.handleChange}>   
          <FormGroup>
              <FormControlLabel
            value="gdn" control={<Radio />}
              label="GDN"
             />
            <FormControlLabel
            value="sanoma" control={<Radio />}
              label="Sanoma"
             />
            <FormControlLabel
            value="alma" control={<Radio />}
              label="Alma"
             />
             
          </FormGroup>
          </RadioGroup>

      </FormControl>

      <TextField
        className="banner-type"
        variant="outlined"
        label="Tyyppi(esim. pystyparaati)"
        onChange={props.onChange}
      ></TextField>
      {props.fileName && (
        <Typography variant="body2">{props.fileName}</Typography>
      )}

      <Button className="upload-button2" variant="contained" component="label">
        <input
          className="get-file-button"
          onChange={props.handleUpload}
          type="file"
          hidden
        />
        <strong>1.</strong>&nbsp; {props.buttonName1}
      </Button>
      <Button
        className="upload-button"
        variant="contained"
        onClick={props.onClick}
        color={props.color}
      >
        <strong>2.</strong>&nbsp;{props.buttonName2}
      </Button>
    </Box>
  );
};
export default CreateBanner;