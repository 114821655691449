import "../css/kampanja.css";

import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Snackbar,
  StyledEngineProvider,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { grey, orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modals from "../components/Modals";
import AddBanner from "../components/AddBanner";
import CreateBanner from "../components/CreateBanner";
import CampaignBanners from "../components/CampaignBanners";
import LoadingSpinner from "../components/loading";
import { authHeader, setJWT } from "../helpers/set-jwt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ClosedCaptionDisabledOutlined } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: grey[900],
    },
  },
});

const Kampanja = (props) => {
  //setJWT();

  const navigate = useNavigate();
  const params = useParams();
  const [campaign, setCampaign] = useState({});
  const [banners, setBanners] = useState([]);
  const [newBanner, setNewBanner] = useState([]);
  const [newBannerCredentials, setNewBannerCredentials] = useState({});
  const [newDesc, setNewDesc] = useState("");
  const [newCampaignName, setNewCampaignName] = useState("");
  const [newCampaignDescription, setNewCampaignDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [previewOn, setPreviewOn] = useState(false);
  const [previewedBanner, setPreviewedBanner] = useState({});
  const [generateValue, setGenerateValue] = useState("");

  useEffect(() => {
    getCampaign(params.id);
  }, [params]);

  const getCampaign = async (campaignId) => {
    const response = await axios.get(
      `https://banner-platform-api.sst.fi/api/campaigns/${campaignId}`,
      {
        headers: authHeader(),
      }
    );
    setCampaign(response.data);
    getAndUpdateBanners();
  };

  const getAndUpdateBanners = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `https://banner-platform-api.sst.fi/api/banners/?filter=campaignId||$eq||${params.id}`,
      {
        headers: authHeader(),
      }
    );
    setBanners(response.data);
    setIsLoading(false);
  };

  const deleteBanner = async (id) => {
    await axios.delete("https://banner-platform-api.sst.fi/api/banners/" + id, {
      headers: authHeader(),
    });

    const rerenderedBanners = banners.filter((banner) => banner.id !== id);
    setBanners(rerenderedBanners);
  };

  const handleChangeNimi = (event) => {
    setNewCampaignName(event.target.value);
  };

  const handleChangeTime = (event) => {
    setNewCampaignDescription(event.target.value);
  };
  const handleDescription = (event) => {
    setNewDesc(event.target.value);
  };
  const handleUpload = (event) => {
    setNewBanner(event.target.files[0]);
    let name = event.target.files[0].name;
    let credentials = {
      campaignId: campaign.id,
      description: newDesc,
    };
    setNewBannerCredentials(credentials);
    setFileName(name + " ladattu");
  };
  const handleGenerate = (event) => {
    setGenerateValue(event.target.value);
  };
  const handleGeneratedUpload = (event) => {
    setNewBanner(event.target.files[0]);
    let name = event.target.files[0].name;
    let credentials = {
      campaignId: campaign.id,
      description: newDesc,
    };
    setNewBannerCredentials(credentials);
    setFileName(name + " ladattu");
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpen2(false);
  };

  const handlePreview = (id) => {
    console.log("pressed");
    if (!previewOn) {
      setPreviewedBanner(banners.find((banner) => banner.id === id));
      setPreviewOn(true);
    } else {
      setPreviewOn(false);
    }
  };
  const handleDialogOpen = () => {
    setOpen3(true);
  };
  const handleClose = () => {
    setOpen3(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpen3(false);
  };
  const handleCloseDialogWithDelete = () => {
    deleteCampaign();
    setOpen3(false);
  };

  const upload = () => {
    let id = "";
    axios
      .post(
        `https://banner-platform-api.sst.fi/api/banners/`,
        newBannerCredentials,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        id = res.data.id;
        const formData = new FormData();
        const imagefile = newBanner;
        formData.append("file", imagefile);
        axios
          .post(
            `https://banner-platform-api.sst.fi/api/banners/upload/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                headers: authHeader(),
              },
            }
          )
          .then((res) => {
            getAndUpdateBanners();
            setOpen2(true);
            setFileName("");
          });
      });
  };

  const generateAndUpload = () => {};

  const updateCampaign = () => {
    const campaignCreds = {
      name: newCampaignName,
      description: newCampaignDescription,
    };

    console.log(campaignCreds);
    axios
      .patch(
        `https://banner-platform-api.sst.fi/api/campaigns/${params.id}`,
        campaignCreds,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setCampaign(res.data);

        setOpen(true);
      });
  };

  const deleteCampaign = async () => {
    let rerenderedBanners = [];
    setIsLoading(true);
    const deletedBanners = await banners.map((banner) =>
      axios
        .delete("https://banner-platform-api.sst.fi/api/banners/" + banner.id, {
          headers: authHeader(),
        })
        .then(
          (rerenderedBanners = banners.filter(
            (banner2) => banner2.id !== banner.id
          ))
        )
    );
    await setBanners(rerenderedBanners);
    setBanners(deletedBanners);
    await axios.delete(
      `https://banner-platform-api.sst.fi/api/campaigns/${params.id}`,
      {
        headers: authHeader(),
      }
    );
    navigate(`/kampanjat/`);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container className="campaign-main-container" maxWidth="xl">
            <Grid spacing={4} container alignItems="baseline">
              <Grid item xs={12} sm={12} md={9}>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 3,
                    bgcolor: "background.paper",
                  }}
                >
                  <div className="box-texts">
                    <Typography
                      className="campaign-name"
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        padding: "20px 30px",
                      }}
                    >
                      {campaign.name}
                    </Typography>

                    
                  
                    <Modals onChangeName={handleChangeNimi} onChangeDesc={handleChangeTime} onClick={updateCampaign}/>
                    <Button
                      className="delete-campaign-button"
                      variant="contained"
                      onClick={handleDialogOpen}
                      sx={{
                        height: 50,
                        mt: 1,
                        "& .MuiButton-root-contained": {
                          color: "black",
                          backgroundColor: "orange",
                        },
                      }}
                    >
                      Poista
                    </Button>
                  </div>
                  <Box
                    sx={{
                      mt: -2,
                      pt: 2,
                      pb: 2,
                      mr: 1,
                      mb: 2,
                    }}
                  >
                    <Typography className="campaign-desc" variant="body1">
                      {campaign.description}
                    </Typography>
                  </Box>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <CampaignBanners
                      which={previewOn}
                      banners={banners}
                      onDelete={deleteBanner}
                      onPreview={handlePreview}
                      width={previewedBanner.width}
                      height={previewedBanner.height}
                      location={previewedBanner.location}
                      onClick={handlePreview}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <AddBanner
                  fileName={fileName}
                  handleUpload={handleUpload}
                  onChange={handleDescription}
                  onClick={() => upload()}
                />
                <CreateBanner
                  componentName={"Luo uusi banneri"}
                  buttonName1={"Lataa aineisto"}
                  buttonName2="Lisää kuvat ZIP-tiedostossa"
                  value={generateValue}
                  handleChange={handleGenerate}
                  onChange={handleDescription}
                  fileName={fileName}
                  handleUpload={handleGeneratedUpload}
                  onClick={() => generateAndUpload()}
                />
                <PreviewUrlContainer />
              </Grid>
            </Grid>
          </Container>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert
              onClose={handleAlertClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {campaign.name + " päivitetty"}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open2}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert
              onClose={handleAlertClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {"Lataus onnistui!"}
            </Alert>
          </Snackbar>
          <Dialog
            open={open3}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Olet poistamassa kampanjaa!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Haluatko varmasti poistaa kampanjan? Poistoa ei voi peruuttaa.
                <br />
                Kampanjan poiston yhteydessä poistuvat myös siihen kuuluneet
                bannerit.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseDeleteDialog}>
                Peruuta
              </Button>
              <Button
                color="error"
                onClick={handleCloseDialogWithDelete}
                autoFocus
              >
                Poista
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

const PreviewUrlContainer = () => {
  let params = useParams();
  let url = `http://localhost:3000/asiakas/kampanjat/${params.id}`;
  function copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(event.target.getAttribute("value"));
  }

  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 2,
        p: 3,

        mt: 3,
        width: "100%",

        bgcolor: "background.paper",
      }}
    >
      <Typography
        className="campaign-name"
        variant="h7"
        sx={{ fontWeight: "bold", display: "inline-block" }}
      >
        Lähetä kampanjan esikatselulinkki asiakkaalle
      </Typography>
      <Typography variant="body1">
        <a
          id="customerLink"
          target={"blank"}
          href={`http://localhost:3000/asiakas/kampanjat/${params.id}`}
        >
          Testaa linkkiä
        </a>
      </Typography>
      <Button
        id="copy"
        value="`http://localhost:3000/asiakas/kampanjat/${params.id`"
        onClick={() => copy()}
      >
        Kopioi leikepöydälle
        <ContentCopyIcon></ContentCopyIcon>
      </Button>
    </Box>
  );
};

export default Kampanja;

/*

import { Outlet, Link, useParams } from "react-router-dom";
import { withRouter } from "../components/withRouter";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { orange, grey, teal, green } from "@mui/material/colors";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  StyledEngineProvider,
  Button,
  IconButton,
  ThemeProvider,
  Alert,
} from "@mui/material";

import "../css/kampanja.css";
import AddBanner from "../components/AddBanner";
import CampaignBanners from "../components/CampaignBanners";

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: grey[900],
    },
  },
});

const GetUrl = (props) => {
  let url = useParams();
  let teksti = url;

  return (
    <Typography hidden id="useparams" label={teksti.id}>
      {teksti.id}
    </Typography>
  );
};

class Kampanja extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeNimi = this.handleChangeNimi.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.state = {
      campaign: {},
      banners: [],
      newBanner: [],
      newBannerCredentials: {},
      newDesc: "",
      url: "",
      newCampaignName: "",
      newCampaignDescription: "",
      flag: true,
    };
  }

  componentDidMount() {
    const id = document.getElementById("useparams").textContent;
    this.setState({ url: id });

    axios
      .get(`https://banner-platform-api.sst.fi/api/campaigns/${id}`)
      .then((res) => {
        this.setState({ campaign: res.data });
        console.log(this.state.campaign);
        this.getAndUpdateBanners();
      });
  }

  getAndUpdateBanners = () => {
    axios
      .get(
        `https://banner-platform-api.sst.fi/api/banners/?filter=campaignId||$eq||${this.state.url}`
      )
      .then((res) => {
        this.setState({ banners: res.data });
      });
  };
  deleteBanner = (id) => {
    axios.delete("https://banner-platform-api.sst.fi/api/banners/" + id).then(
      this.setState({
        banners: this.state.banners.filter((banner) => banner.id !== id),
      })
    );
  };

  handleChangeNimi = (event) => {
    this.setState({ newCampaignName: event.target.value });
  };

  handleChangeTime = (event) => {
    this.setState({ newCampaignDescription: event.target.value });
  };
  handleDescription = (event) => {
    this.setState({ newDesc: event.target.value });
  };
  handleUpload = (event) => {
    this.setState({ newBanner: event.target.files[0] });
    console.log(this.state.url);

    let credentials = {
      campaignId: this.state.url,
      description: this.state.newDesc,
    };
    this.setState({ newBannerCredentials: credentials });
  };

  upload = () => {
    let id = "";
    axios
      .post(
        `https://banner-platform-api.sst.fi/api/banners/`,
        this.state.newBannerCredentials
      )
      .then((res) => {
        id = res.data.id;
        const formData = new FormData();
        const imagefile = this.state.newBanner;
        formData.append("file", imagefile);
        axios
          .post(
            `https://banner-platform-api.sst.fi/api/banners/upload/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            this.getAndUpdateBanners();
          });
      });
  };

  preview = (id) => {
    console.log("Fired");
    const banner = this.state.banners.find((banner) => banner.id === id);
    return (
      <div>
        <Box container>
          <iframe
            src={`https://banner-platform-api.sst.fi/${banner.location}`}
            scrolling="no"
            width={banner.width}
            height={banner.height}
            title={banner.title}
          ></iframe>
          <Button>Close</Button>
        </Box>
      </div>
    );
  };

  updateCampaign = () => {
    const campaignCreds = {
      name: this.state.newCampaignName,
      description: this.state.newCampaignDescription,
    };
    console.log(campaignCreds);
    axios
      .patch(
        `https://banner-platform-api.sst.fi/api/campaigns/${this.state.url}`,
        campaignCreds
      )
      .then((res) => this.setState({ campaign: res.data }));
  };

  deleteCampaign = async () => {
    await axios.delete(`https://banner-platform-api.sst.fi/api/campaigns/${this.state.url}`)
    this.props.navigate('localhost:3000/kampanjat')
  };

  render() {
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Container className="campaign-main-container" maxWidth="xl">
              <Grid spacing={4} container alignItems="baseline">
                <Grid item xs={12} sm={12} md={9}>
                  <Box
                    sx={{
                      boxShadow: 1,
                      borderRadius: 2,
                      p: 3,
                      bgcolor: "background.paper",
                    }}
                  >
                    <div className="box-texts">
                      <Typography
                        className="campaign-name"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          display: "inline-block",
                          padding: "20px 30px",
                          
                        }}
                      >
                        {this.state.campaign.name}
                      </Typography>

                      <TextField
                        className="edit-campaign-name"
                        sx={{
                          display: "inline-block",
                          mr: 5,

                          "& .MuiInputLabel-root": { color: "black" },
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "orange" },
                          },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "orange",
                            },
                            "& .css-1sumxir-MuiOutlinedInput-root.MuiFocused": {
                              color: "black",
                            },
                          },
                        }}
                        variant="outlined"
                        value={this.state.newCampaignName}
                        label="Kampanjan nimi"
                        onChange={this.handleChangeNimi}
                      ></TextField>
                      <TextField
                        className="edit-campaign-time"
                        sx={{
                          display: "inline-block",

                          "& .MuiInputLabel-root": { color: "black" },
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "orange" },
                          },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "orange",
                            },
                            "& .css-1sumxir-MuiOutlinedInput-root.MuiFocused": {
                              color: "black",
                            },
                          },
                        }}
                        variant="outlined"
                        value={this.state.newCampaignCredentials}
                        label="Lisätiedot"
                        onChange={this.handleChangeTime}
                      ></TextField>
                                            <Button
                        className="update-button"
                        variant="contained"
                        onClick={() => this.updateCampaign()}
                        sx={{
                          height: 50,
                          mt: 1,
                          mr:3,
                          "& .MuiButton-root-contained": {
                            color: "black",
                            backgroundColor: "orange",
                          },
                        }}
                      >
                       Päivitä
                      </Button>
                      <Button
                        className="delete-campaign-button"
                        variant="contained"
                        onClick={() => this.deleteCampaign()}
                        sx={{
                          height: 50,
                          mt: 1,
                          "& .MuiButton-root-contained": {
                            color: "black",
                            backgroundColor: "orange",
                          },
                        }}
                      >
                        Poista
                      </Button>
                    </div>
                    <Box
                      sx={{
                        mt: -2,
                        pt: 2,
                        pb: 2,
                        mr: 1,
                        mb: 2,
                      }}
                    >
                      <Typography className="campaign-desc" variant="body1">
                        {this.state.campaign.description}
                      </Typography>
                    </Box>
                    <CampaignBanners
                      banners={this.state.banners}
                      onDelete={this.deleteBanner}
                      onPreview={this.preview}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <AddBanner
                    handleUpload={this.handleUpload}
                    onChange={this.handleDescription}
                    onClick={() => this.upload()}
                    color={this.state.flag ? "primary" : "success"}
                  />
                  <PreviewUrlContainer />
                </Grid>
                
              </Grid>
              
            </Container>
            <GetUrl onChange={this.handleUrl} />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
}

const PreviewUrlContainer = () => {
  let params = useParams();
  return (

    <Box 
      sx={{

        boxShadow: 1,
        borderRadius: 2,
        p: 3,

        mt: 3,
        width: "100%",
        
        bgcolor: "background.paper",
      }}
    >
      <Typography
        className="campaign-name"
        variant="h7"
        sx={{ fontWeight: "bold", display: "inline-block" }}
      >
        Lähetä kampanjan esikatselulinkki asiakkaalle
      </Typography>
      <Typography variant="body1">
        <strong>URL: </strong>
        <a
          target={"blank"}
          href={`http://localhost:3000/asiakas/kampanjat/${params.id}`}
        >
          {`http://localhost:3000/asiakas/kampanjat/${params.id}`}
        </a>
        </Typography>
    </Box>
 
  );
};
export default withRouter(Kampanja)
*/
