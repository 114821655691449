import '../css/kampanjaUI.css';

import { Alert, Box, Button, Container, Grid, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { StyledEngineProvider } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { authHeader, setJWT } from '../helpers/set-jwt';

const KampanjaUI = (props) => {
  //setJWT();

  const navigate = useNavigate();
  const [campaigns, setCampaigns] = React.useState([]);
  const [id, valitseKampanja] = React.useState("");
  const [name, valitseNimi] = React.useState("");
  const [desc, valitseDesc] = React.useState("");
  const [open, setOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState("");

  useEffect(() => {
    axios
      .get("https://banner-platform-api.sst.fi/api/campaigns", {
        headers: authHeader(),
      })
      .then((res) => {
        setCampaigns(res.data);
        console.log("GET Successful");
        console.log(campaigns);
      });
  }, []);

  const handleChange = (event) => {
    valitseKampanja(event.target.value);
  };
  const handleChangeNimi = (event) => {
    valitseNimi(event.target.value);
  };
  const handleChangeDesc = (event) => {
    valitseDesc(event.target.value);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const uusiKampanja = async () => {
    const campaignInfo = {
      name: name,
      description: desc,
    };
    await axios.post(
      "https://banner-platform-api.sst.fi/api/campaigns",
      campaignInfo,
      {
        headers: authHeader(),
      }
    );
    const updatedCampaings = await axios.get(
      "https://banner-platform-api.sst.fi/api/campaigns",
      {
        headers: authHeader(),
      }
    );
    setCampaigns(updatedCampaings.data);
    setNewCampaignName(name);
    setOpen(true);
  };

  return (
    <StyledEngineProvider injectFirst>
      <main>
        <div>
          <Container className={"xxx"} maxWidth="md">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    minWidth: 400,
                    bgcolor: orange[500],
                  }}
                >
                  <div className="add-campaign-container">
                    <Typography className="add-campaign" variant="h5">
                      Lisää kampanja
                    </Typography>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "black" },
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { borderColor: "black" },
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "black",
                          },
                          "& .css-1sumxir-MuiOutlinedInput-root.MuiFocused": {
                            color: "white",
                          },
                        },
                      }}
                      className="campaign-name-input"
                      variant="outlined"
                      label="Kampanjan nimi"
                      onChange={handleChangeNimi}
                    ></TextField>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "black" },
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { borderColor: "black" },
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "black",
                          },
                          "& .css-1sumxir-MuiOutlinedInput-root.MuiFocused": {
                            color: "white",
                          },
                        },
                      }}
                      className="campaign-name-input"
                      variant="outlined"
                      label="Kampanjan tiedot"
                      onChange={handleChangeDesc}
                    ></TextField>
                    <div>
                      <Button
                        className="add-campaign-button"
                        onClick={() => uusiKampanja()}
                        variant="contained"
                      >
                        Lisää
                      </Button>
                      <div>
                        {
                          <Snackbar
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleAlertClose}
                          >
                            <Alert
                              onClose={handleAlertClose}
                              severity="success"
                              sx={{ width: "100%" }}
                            >
                              {newCampaignName + " lisätty"}
                            </Alert>
                          </Snackbar>
                        }
                      </div>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    minWidth: 400,
                    bgcolor: "background.paper",
                  }}
                >
                  <div className="campaign-select-container">
                    <Typography className="old-campaigns" variant="h5">
                      Lisätyt kampanjat
                    </Typography>

                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "black" },
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { borderColor: "orange" },
                        },
                        "& .MuiOutlinedInput-root-MuiOutlinedInput-root.Mui-focused":
                          {
                            "& > fieldset": {
                              borderColor: "orange",
                            },
                            "& .css-1sumxir-MuiOutlinedInput-root.MuiFocused": {
                              color: "black",
                            },
                          },
                      }}
                      className="campaign-select-input"
                      labelid="SelectCampaignLabel"
                      id="campaign"
                      value={id}
                      label="Kampanja"
                      onChange={handleChange}
                      select
                    >
                      {campaigns.map((campaign) => (
                        <MenuItem key={campaign.id} value={campaign.id}>
                          {campaign.name}
                        </MenuItem>
                      ))}
                      
                    </TextField>

                    <Button
                      className="select-campaign-button"
                      onClick={() => navigate(`/kampanjat/${id}`)}
                      variant="contained"
                    >
                      Valitse
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Outlet />
        </div>
      </main>
    </StyledEngineProvider>
  );
};

export default KampanjaUI;
