import { Outlet, Link } from "react-router-dom";
import {
  Typography,
  AppBar,
  CssBaseline,
  Toolbar,
  Container,
  Box,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { ReactComponent as LogoSvg } from "../images/sst_logo.svg";
import "../css/header.css";

const CustomerHeader = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <>
        <CssBaseline />
        <AppBar className="app-bar" position="relative" color="primary">
          <Toolbar className="tool-bar">
            <LogoSvg className="logo"></LogoSvg>

            <Typography className="mainostoimisto" variant="h4">
              MAINOSTOIMISTO SST
            </Typography>

            <Typography className="digisovellukset" variant="h7">
              Digisovellukset
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          <Typography
            className="header-text"
            variant="h1"
            align="left"
            gutterBottom
          >
            {props.customer}
          </Typography>
          <div class="divider"></div>
          <Typography
            className="header-p"
            variant="h5"
            align="center"
            paragraph
          >
            {props.desc}
          </Typography>
        </Container>
      </>
    </StyledEngineProvider>
  );
};
export default CustomerHeader;
