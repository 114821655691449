import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "../css/modals.css";



const Modals = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
         sx={{
            height: 50,
            mt: 1,
            mr: 2,
            "& .MuiButton-root-contained": {
              color: "black",
              backgroundColor: "orange",
            },
          }}
      variant='contained' onClick={handleOpen}>Päivitä</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
            boxShadow: 5,
            borderRadius: 2,
            p: 2.5,
            mt: 1,
            
            bgcolor: "background.paper",}} >
                
            <IconButton sx={{float: "right", mt:-2, mr:-2}}onClick={handleClose}><CloseIcon /></IconButton>    
          <Typography  id="modal-modal-title" variant="h6" component="h2">
            Päivitä kampanjan nimi
          </Typography>
          
          
          <TextField
        sx={{mt:1}}
        variant="outlined"
        label="Kampanjan nimi"
        onChange={props.onChangeName}
      >

          </TextField>

        <TextField
        sx={{mt:1}}
        variant="outlined"
        label="Kampanjan tiedot"
        onChange={props.onChangeDesc}
      ></TextField>

    <Button className="update-button"
        sx={{
            height: 50,
            mt: 1,
            "& .MuiButton-root-contained": {
              color: "black",
              backgroundColor: "orange",
            },
          }}
        variant='contained' onClick={props.onClick}>Päivitä
        
        </Button>
        
        </Box>
      </Modal>
    </div>
  );
}
export default Modals;