import "../css/kampanja.css";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  StyledEngineProvider,
  Button,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { Outlet, Link, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import BannerCard from "../components/BannerCard";
const CampaignBanners = (props) => {
  console.log("CampaignBanners päivittyi: " + props);

  return (
    <Grid container spacing={2}>
      {props.which && (
        <PreviewOneBanner
          location={props.location}
          width={props.width}
          height={props.height}
          onClick={props.onClick}
        />
      )}
      {props.banners.map((banner) => {
        return (
          <BannerCard
            key={banner.id}
            size={banner.width + "x" + banner.height}
            description={banner.description}
            thumbnail={banner.thumbnail}
            bannerId={banner.id}
            onDelete={props.onDelete}
            preview={props.onPreview}
          />
        );
      })}
    </Grid>
  );
};

const PreviewOneBanner = (props) => {
  return (
    <div>
      <Box
        sx={{
          boxShadow: 10,
          borderRadius: 2,
          backgroundColor: "whitesmoke",
          p: 2,
          pb: 5,
          m: 1,
        }}
      >
        <iframe
          src={`https://banner-platform-api.sst.fi/${props.location}`}
          scrolling="no"
          width={props.width}
          height={props.height}
          title={props.title}
        ></iframe>
        <Typography variant="h6" sx={{ fontWeight: "bold", ml: 2 }}>
          {" "}
          {props.header}
        </Typography>
        <Typography variant="h7" sx={{ fontWeight: "bold", ml: 2 }}>
          {" "}
          {props.desc}
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => props.onClick}
        >
          Takaisin
        </Button>
      </Box>
    </div>
  );
};
export default CampaignBanners;
