export function setJWT() {
  const cookieName = "CF_Authorization";
  const lsKey = "authToken";
  const cookie = getCookieValue(cookieName);

  if (!cookie) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Authentication required. Reload?.")) {
      window.location.href = "/";
      return;
    }
  }

  localStorage.setItem(lsKey, cookie);
}

export const token = () => {
  return localStorage.getItem("authToken");
};

export const authHeader = () => {
  return {
    Authorization: `Bearer ${token()}`,
  };
};

function getCookieValue(name) {
  return (
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""
  );
}
