import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import Kampanja from './routes/Kampanja';
import KampanjaUI from './routes/KampanjaUI';
import Login from './routes/Login';
import Preview from './routes/Preview';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/login" element={<Login />} />
        <Route path="/kampanjat" element={<KampanjaUI />} />
        <Route path="/kampanjat/:id" element={<Kampanja />} />
      </Route>
      <Route path="asiakas/kampanjat/:id" element={<Preview />} />

      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There is nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>
);
