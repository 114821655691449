import "../css/kampanja.css";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  StyledEngineProvider,
  Button,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { Outlet, Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

const AddBanner = (props) => {
  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 2,
        p: 2,

        bgcolor: "background.paper",
      }}
    >
      <Typography className="campaign-name" variant="h5">
        Lisää sovellus
      </Typography>

      <TextField
        className="banner-type"
        variant="outlined"
        label="Tyyppi(esim. pystyparaati)"
        onChange={props.onChange}
      ></TextField>
      {props.fileName && (
        <Typography variant="body2">{props.fileName}</Typography>
      )}

      <Button className="upload-button2" variant="contained" component="label">
        <input
          className="get-file-button"
          onChange={props.handleUpload}
          type="file"
          hidden
        />
        <strong>1.</strong>&nbsp; Lataa aineisto
      </Button>
      <Button
        className="upload-button"
        variant="contained"
        onClick={props.onClick}
        color={props.color}
      >
        <strong>2.</strong>&nbsp;Lisää zip-tiedosto
      </Button>
    </Box>
  );
};

export default AddBanner;
