import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { Typography, AppBar, CardMedia, Container, Grid, Box, Color, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, Button, CssBaseline, Avatar, TextField } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
<link rel="stylesheet" href="../css/kampanjaUI.css"></link>
const Login = () => {
    let navigate = useNavigate();
    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      await submitForm(data)
      navigate("/kampanjat/", { replace: true });
      console.log({
        email: data.get('email'),
        password: data.get('password')
       
      });
    
    }
    const submitForm = () => {
      return "success"
    }
    return(
      <Container component="main" maxWidth="sx">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{m: 1, bgColor: 'secondary.main'}}>
            <LockOutlinedIcon/>
          </Avatar>
            <Typography className="log-in" variant="h5">
              Kirjaudu sisään
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Sähköposti"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Salasana"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 1, mb: 2, pt: 1, pb: 1, pl: 3, pr: 3, backgroundColor: 'orange', color: 'black', display: 'block', ":hover":{color: 'orange', backgroundColor: 'black'} }}
              >
                Kirjaudu
              </Button>
              <Grid container>
                <Grid item xs>
  
                </Grid>
              </Grid>
            </Box>
        </Box>
      </Container>
    )
  }
  export default Login;